import React from "react"
import { Card, Image, Container, Row, Col } from "react-bootstrap"
import missionNoob1 from "../../../images/missionNoob1.png"
import missionBanner1 from "../../../images/missionBanner1.jpeg"
import MissionGrid from "../../../images/MissionGrid.jpg"
import sample1 from "../../../images/Sample-1.png"
import sample2 from "../../../images/Sample-2.png"
import sample3 from "../../../images/Sample-3.png"
import sample4 from "../../../images/Sample-4.png"
import sample5 from "../../../images/Sample-5.png"
import sample6 from "../../../images/Sample-6.png"
import SiteNoob from "../../../images/SiteNoob.png"
import { isMobile } from "../../../utils/device"

type MissionProps = {}

const Mission: React.FC<MissionProps> = ({}) => {
  const missionImage =
    isMobile()
      ? <Image src={SiteNoob} className="sample-noobs-image" />
      : <Image src={missionBanner1} className="sample-noobs-image" />

  return (
    <>
      <Container id="mission-intro" className="noobs-mission-section d-flex flex-column align-items-center justify-content-center" fluid>
        <Row className="d-flex align-items-center justify-content-center">
          <Col className="col-12 col-sm-12 col-lg-7 d-flex flex-column align-items-center justify-content-center">
            <p className="terms-text">
              <span className="noobs-mission-text-intro">Hello, NOOBS!</span> We are so grateful for your support of our project. NOOBS is unlike your typical 10k generative collectibles project. We did not spend weeks hyping a project based on a theoretical roadmap that may or may not come to fruition, rather we made the conscious decision to stealth launch our project with minimal hype and no publicly available roadmap whatsoever.
            </p>
            <p className="terms-text">
              The reason for this was a simple one. We recognize the risks of unrealistic expectations and the damage that can do not only to a project but also to the many collectors that may be susceptible to a FOMO-driven hype cycle. For a project like ours, which is meant to be inclusive, particularly of new entrants to the space, this concern was even more pronounced. However, that does not mean we do not have long-term plans for our project. Far from it. Yet while our intent has always been to focus purely on the collectible aspect of our project during this initial phase, we’ve listened to our community as they have made clear there is an interest to further understand our vision, so without further ado, we’d like to present our Mission Statement.
            </p>
          </Col>
          <Col className="col-0 col-lg-1"></Col>
          <Col className="col-12 col-sm-12 col-lg-4 d-flex align-items-center justify-content-center">
            <Image src={missionNoob1} className="sample-noobs-image" />
          </Col>
        </Row>
      </Container>
      <Container id="mission-statement" className="noobs-mission-section" fluid>
        <Row className="d-flex align-items-center justify-content-center">
          <Col>
            <h1 className="terms-header">NOOBS: MISSION STATEMENT</h1>
            <p className="terms-text">
              <span className="noobs-mission-text-intro">Why a Mission Statement and not a Roadmap?</span> Nothing against projects that opt for the latter, but let’s take a step back to help you understand the genesis of our project and how our vision came to be. NOOBS became a reality when our Chief NOOB Officer (Sven), Chief NOOB Artist (Mike), and Dev Team (Seinfeld, Hey Goomba, bfjoslf, Smo Smo) came together to build a project that could offer a fun, accessible entry-level collectible to both NOOBS and OGs alike. We knew we had the art to fit this concept from our artist and the technical skills to build it from our devs, but what we wanted was for the project to go beyond simply offering an avatar. Instead, we wanted to offer an avatar that could simultaneously serve as a gateway to the entire NFT space.
            </p>
            {missionImage}
            <p className="terms-text">
              If our project was going to be geared towards encouraging adoption of blockchain technology and on-boarding of new entrants into the NFT space, we had to be very thoughtful with respect to how we go about messaging that. For us, the first thing that went out the window was a roadmap based on % sold activation. We would run giveaways and reward collectors, but focusing our project on next steps based on our sales progress would not help us achieve these goals. Instead, we kept our focus on completing the art and building out the technical aspects of the project before even doing an ounce of marketing or other outreach. We opened our Discord server on a Thursday and stealth launched on a Sunday evening, just 72 hours later. To be honest, we weren’t sure what to expect. We knew there were risks to launching in this manner, but our project’s success was never going to be judged by an instant sellout. If anything, a stealth launch would better help us achieve our goals because it would allow for slower adoption with less competition (meaning lower gas prices), making it easier for actual NOOBS to mint.
            </p>
            <p className="terms-text">
              <span className="noobs-mission-text-intro">Have you ever stopped to consider the mental hurdle that first-time buyers face during a hyped launch, when gas prices are astronomical and inexperienced buyers fear the impact of failed transactions?</span> Our approach certainly alleviated these types of concerns, but we have no control over gas prices and unfortunately those have still run high in the days subsequent to launch. We did achieve an impressive number of early sales before the pace slowed, coinciding with elevated gas prices, and we know our community would like to see continued progress towards an eventual sellout, but we would prefer if new buyers continue to wait until market conditions are more favorable for purchase. <span className="noobs-mission-text-intro">Again, it’s not the speed with which you sell that matters but what you do with your project once the sale is complete.</span> Which begs the question: what does NOOBS look like once all NOOBS have been minted?
            </p>
            <p className="terms-text">Preamble complete. Hope you are all still reading.</p>
            </Col>
        </Row>
      </Container>
      <Container id="phase-two" className="noobs-mission-section" fluid>
        <Row>
          <Col>
            <h1 className="terms-header">NOOBS: PHASE TWO</h1>
            <p className="terms-text">
              Upon our initial sale’s completion, we will shift our focus towards phase two of our project. Each and every NOOB will have a role to play in phase two, but while we encourage our community to enjoy their NOOBS as both an avatar and a collectible item, the avatar aspect of our project is not how we will judge our project’s success long-term. Once many first-time buyers have acquired NOOBS to make their initial foray into the NFT space (with other relatively inexperienced buyers making it an early part of their collection), the challenge for us becomes <span className="noobs-mission-text-intro">how do we help our collector base “level up” their NFT game from NOOB to OG.</span>
            </p>
            <Row className="d-flex align-items-center justify-content-center">
              <Col className="col-12 col-sm-12 col-lg-5 d-flex align-items-center justify-content-center">
                <Image src={MissionGrid} className="sample-noobs-image" />
              </Col>
              <Col className="col-0 col-lg-1"></Col>
              <Col className="col-12 col-sm-12 col-lg-6">
                <p className="terms-text">
                  Relying on our collective experience in the NFT space, and particularly Sven’s personal experience as a collector, each NOOB will function as an access token that will enable opportunities across various NFT market segments including digital art, photography, music, gaming, and other emerging sectors. We have already engaged several creators (some of whom are NOOBS themselves) and have received positive feedback regarding our plans to offer our community the ability to acquire other types of NFTs. Without going deep into the mechanics of what we have planned, which admittedly we are still finalizing, the basic principle is this: <span className="noobs-mission-text-intro">if you hold NOOBS, you will have special access to NFTs from leading creators as well as some who perhaps haven’t yet found success in the space.</span> That’s right — NOOBS can also be a portal to help creators “level up” from NOOB to OG, and we’ll be looking to provide special assistance to artists or developers from within our community to help them achieve this (more broadly, educational initiatives will be a focus of ours).
                </p>
              </Col>
            </Row>
            <p className="terms-text">
              Ultimately, when you become a member of our community, you are getting a great collectible item that we hope you become quite fond of. And for as long as you hold your NOOBS you will also receive access to additional NFT releases alongside other benefits. Some of these additional NFTs may be in the form of more typical avatar airdrops — <span className="noobs-mission-text-intro">after all, if we’re going to help our NOOBS “level up” then we better make sure your NOOBS “level up” alongside you</span> — while others may rely on common NFT mechanics (i.e., burns, auctions, collector challenges). We’re also hoping to surprise you along the way.
            </p>
            <p className="terms-text">
              We are grateful for your support, and if you’re still reading this after 1,000 words, we are excited to have you join us on this journey as we seek to push the NFT space forward in a positive direction together. <span className="noobs-mission-text-intro">#SENDNOOBS</span>
            </p>
            <p className="terms-text">Sincerely,</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row key="sven" bsPrefix="row noob-cards-row">
              <Col key="sven" className="noob-card">
                <a href="https://twitter.com/sven_nft" target="_blank" className="noob-link">
                  <Card style={{ width: '8rem' }} className="no-border">
                    <Card.Img variant="top" src={sample1} />
                    <Card.Body>
                      <Card.Title style={{ textAlign: "center", fontSize: "1rem" }}>SVEN</Card.Title>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col key="mike" className="noob-card">
                <a href="https://twitter.com/mikealikenft" target="_blank" className="noob-link">
                  <Card style={{ width: '8rem' }} className="no-border">
                    <Card.Img variant="top" src={sample2} />
                    <Card.Body>
                      <Card.Title style={{ textAlign: "center", fontSize: "1rem" }}>MIKE</Card.Title>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col key="seinfeld" className="noob-card">
                <a href="https://twitter.com/seinfeldnft" target="_blank" className="noob-link">
                  <Card style={{ width: '8rem' }} className="no-border">
                    <Card.Img variant="top" src={sample3} />
                    <Card.Body>
                      <Card.Title style={{ textAlign: "center", fontSize: "1rem" }}>SEINFELD</Card.Title>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col key="heygoomba" className="noob-card">
                <a href="https://twitter.com/GoombaHey" target="_blank" className="noob-link">
                  <Card style={{ width: '8rem' }} className="no-border">
                    <Card.Img variant="top" src={sample4} />
                    <Card.Body>
                      <Card.Title style={{ textAlign: "center", fontSize: "1rem" }}>HEY GOOMBA</Card.Title>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col key="bfjoslf" className="noob-card">
                <a href="https://twitter.com/bfjoslf" target="_blank" className="noob-link">
                  <Card style={{ width: '8rem' }} className="no-border">
                    <Card.Img variant="top" src={sample5} />
                    <Card.Body>
                      <Card.Title style={{ textAlign: "center", fontSize: "1rem" }}>BFJOSLF</Card.Title>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col key="smosmo" className="noob-card">
                <a href="https://twitter.com/MrSmoSmo" target="_blank" className="noob-link">
                  <Card style={{ width: '8rem' }} className="no-border">
                    <Card.Img variant="top" src={sample6} />
                    <Card.Body>
                      <Card.Title style={{ textAlign: "center", fontSize: "1rem" }}>SMO SMO</Card.Title>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Mission
